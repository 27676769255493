// PerformancePlanData.js
import React, { useState } from 'react';
import './PerformancePlanData.css'; // Ensure this file exists for styling

const PerformancePlanData = () => {
    const currentYear = new Date().getFullYear();

    // State for managing performance plan entries
    const [entries, setEntries] = useState([
        { id: '', class: '', segment: '', item: '', metric: '', month: '', year: currentYear.toString() },
    ]);

    // State for feedback messages
    const [message, setMessage] = useState('');

    // State variables for fetching existing data
    const [fetchMonth, setFetchMonth] = useState('');
    const [fetchYear, setFetchYear] = useState(currentYear.toString());
    const [saleType, setSaleType] = useState('all'); // 'new', 'used', 'all'

    // Predefined options for dropdowns
    const classOptions = [
        { value: '', label: 'Select Class' },
        { value: 'variable', label: 'Variable' },
        { value: 'fixed', label: 'Fixed' },
    ];

    const segmentOptions = [
        { value: '', label: 'Select Segment' },
        { value: 'sales', label: 'Sales' },
        { value: 'service', label: 'Service' },
    ];

    const itemOptions = [
        { value: '', label: 'Select Item' },
        { value: 'new units sold', label: 'New Units Sold' },
        { value: 'used units sold', label: 'Used Units Sold' },
        { value: 'new back gross profit', label: 'New Back Gross Profit' },
        { value: 'new front gross profit', label: 'New Front Gross Profit' },
        { value: 'used back gross profit', label: 'Used Back Gross Profit' },
        { value: 'used front gross profit', label: 'Used Front Gross Profit' },
    ];

    const monthOptions = [
        { value: '', label: 'Select Month' },
        { value: '01', label: 'January' },
        { value: '02', label: 'February' },
        { value: '03', label: 'March' },
        { value: '04', label: 'April' },
        { value: '05', label: 'May' },
        { value: '06', label: 'June' },
        { value: '07', label: 'July' },
        { value: '08', label: 'August' },
        { value: '09', label: 'September' },
        { value: '10', label: 'October' },
        { value: '11', label: 'November' },
        { value: '12', label: 'December' },
    ];

    const yearOptions = Array.from({ length: 10 }, (_, i) => ({
        value: (currentYear - i).toString(),
        label: (currentYear - i).toString(),
    }));

    // Handler for form field changes
    const handleChange = (index, e) => {
        const { name, value } = e.target;
        const newEntries = [...entries];
        newEntries[index][name] = value;
        setEntries(newEntries);
        console.log(`Entry ${index + 1} Updated:`, newEntries[index]); // Debugging Log
    };

    // Handler to add a new empty entry
    const addEntry = () => {
        setEntries([...entries, { id: '', class: '', segment: '', item: '', metric: '', month: '', year: currentYear.toString() }]);
    };

    // Handler to remove an entry
    const removeEntry = (index) => {
        const newEntries = [...entries];
        newEntries.splice(index, 1);
        setEntries(newEntries);
    };

    // Function to fetch existing performance data
    const fetchPerformanceData = async () => {
        if (!fetchMonth || !fetchYear) {
            setMessage('Please select both month and year to fetch data.');
            return;
        }

        try {
            const token = localStorage.getItem('accessToken');
            if (!token) {
                setMessage('User not authenticated. Please log in.');
                return;
            }

            const response = await fetch(
                `${process.env.REACT_APP_BACKEND_URL}/api/performance-plan?month=${fetchMonth}&year=${fetchYear}&saleType=${saleType}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );

            const result = await response.json();

            if (response.ok) {
                if (result.length === 0) {
                    setMessage('No performance data found for the selected period.');
                    return;
                }

                // Transform fetched data to match the entries structure, including id, class, and segment
                const transformedEntries = result.map((item) => ({
                    id: item.id, // Include the unique identifier
                    class: item.class,
                    segment: item.segment,
                    item: item.item,
                    metric: item.metric,
                    month: fetchMonth,
                    year: fetchYear.toString(),
                }));

                setEntries(transformedEntries);
                setMessage(`Fetched ${transformedEntries.length} entries for ${fetchMonth}/${fetchYear}.`);
            } else {
                setMessage(`Error: ${result.error || result.message}`);
            }
        } catch (error) {
            console.error('Error fetching performance data:', error);
            setMessage('An error occurred while fetching performance data.');
        }
    };

    // Function to handle form submission (insertion and updates)
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Debugging: Log current entries before validation
        console.log('Submitting Entries:', entries);

        // Validate all entries
        for (let i = 0; i < entries.length; i++) {
            const entry = entries[i];
            const missingFields = [];
            if (!entry.class) missingFields.push('Class');
            if (!entry.segment) missingFields.push('Segment');
            if (!entry.item) missingFields.push('Item');
            if (entry.metric === '') missingFields.push('Metric');
            if (!entry.month) missingFields.push('Month');
            if (!entry.year) missingFields.push('Year');

            if (missingFields.length > 0) {
                setMessage(`Please fill in the following fields for Entry ${i + 1}: ${missingFields.join(', ')}`);
                return;
            }
        }

        try {
            // Retrieve JWT from localStorage or another storage mechanism
            const token = localStorage.getItem('accessToken'); // Adjust based on your auth implementation

            if (!token) {
                setMessage('User not authenticated. Please log in.');
                return;
            }

            // Prepare separate arrays for insertions and updates
            const entriesToInsert = [];
            const entriesToUpdate = [];

            entries.forEach((entry) => {
                const formattedEntry = {
                    class: entry.class,
                    segment: entry.segment,
                    item: entry.item,
                    metric: parseFloat(entry.metric),
                    expected_date: `${entry.year}-${entry.month}-01`, // Standardize to first day of the month
                };

                if (entry.id) {
                    // Entry has an id, prepare for update
                    entriesToUpdate.push({ id: entry.id, ...formattedEntry });
                } else {
                    // Entry has no id, prepare for insertion
                    entriesToInsert.push(formattedEntry);
                }
            });

            // Initialize an array to hold all promises
            const allPromises = [];

            // Handle insertions (POST requests)
            if (entriesToInsert.length > 0) {
                allPromises.push(
                    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/perf-plan`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                        body: JSON.stringify(entriesToInsert),
                    })
                        .then(response => response.json())
                );
            }

            // Handle updates (PUT requests)
            entriesToUpdate.forEach((entryToUpdate) => {
                allPromises.push(
                    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/perf-plan/${entryToUpdate.id}`, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                        body: JSON.stringify({
                            class: entryToUpdate.class,
                            segment: entryToUpdate.segment,
                            item: entryToUpdate.item,
                            metric: entryToUpdate.metric,
                            expected_date: entryToUpdate.expected_date,
                        }),
                    })
                        .then(response => response.json())
                );
            });

            // Execute all requests
            const results = await Promise.all(allPromises);

            // Analyze results
            let successCount = 0;
            let errorMessages = [];

            results.forEach((result, index) => {
                if (result.message) {
                    successCount += 1;
                } else if (result.error) {
                    errorMessages.push(result.error);
                }
            });

            if (successCount > 0) {
                setMessage(`Successfully processed ${successCount} entries.`);
            }

            if (errorMessages.length > 0) {
                setMessage(prev => prev ? `${prev} Errors: ${errorMessages.join('; ')}` : `Errors: ${errorMessages.join('; ')}`);
            }

            // Reset entries if all operations were insertions
            if (entriesToUpdate.length === 0) {
                setEntries([{ id: '', class: '', segment: '', item: '', metric: '', month: '', year: currentYear.toString() }]);
            }

        } catch (error) {
            console.error('Error submitting data:', error);
            setMessage('An error occurred while submitting data.');
        }
    };

    return (
        <div className="performance-plan-container">

            {/* Fetch Existing Data Section */}
            <div className="fetch-data-section">
                <h2>Retrieve Existing Performance Plan Data</h2>
                <div className="form-group">
                    <label htmlFor="fetch-month">Month:</label>
                    <select
                        id="fetch-month"
                        value={fetchMonth}
                        onChange={(e) => setFetchMonth(e.target.value)}
                    >
                        <option value="">Select Month</option>
                        {monthOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor="fetch-year">Year:</label>
                    <select
                        id="fetch-year"
                        value={fetchYear}
                        onChange={(e) => setFetchYear(e.target.value)}
                    >
                        <option value="">Select Year</option>
                        {yearOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor="saleType">Sale Type:</label>
                    <select
                        id="saleType"
                        value={saleType}
                        onChange={(e) => setSaleType(e.target.value)}
                    >
                        <option value="all">All</option>
                        <option value="new">New</option>
                        <option value="used">Used</option>
                    </select>
                </div>

                <button type="button" onClick={fetchPerformanceData} className="fetch-button">
                    Fetch Performance Data
                </button>
            </div>

            {/* Submission Form */}
            <form onSubmit={handleSubmit} className="performance-plan-form">
                <h2>Submit Performance Plan Entries</h2>
                {entries.map((entry, index) => (
                    <div key={entry.id || index} className="entry-group">
                        {/* Hidden ID Field */}
                        {entry.id && (
                            <input type="hidden" name="id" value={entry.id} />
                        )}

                        <h3>Entry {index + 1}</h3>
                        <div className="form-group">
                            <label htmlFor={`class-${index}`}>Class:</label>
                            <select
                                id={`class-${index}`}
                                name="class"
                                value={entry.class}
                                onChange={(e) => handleChange(index, e)}
                                required
                            >
                                {classOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="form-group">
                            <label htmlFor={`segment-${index}`}>Segment:</label>
                            <select
                                id={`segment-${index}`}
                                name="segment"
                                value={entry.segment}
                                onChange={(e) => handleChange(index, e)}
                                required
                            >
                                {segmentOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="form-group">
                            <label htmlFor={`item-${index}`}>Item:</label>
                            <select
                                id={`item-${index}`}
                                name="item"
                                value={entry.item}
                                onChange={(e) => handleChange(index, e)}
                                required
                            >
                                {itemOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="form-group">
                            <label htmlFor={`metric-${index}`}>Metric:</label>
                            <input
                                type="number"
                                id={`metric-${index}`}
                                name="metric"
                                value={entry.metric}
                                onChange={(e) => handleChange(index, e)}
                                required
                                placeholder="e.g., 120.00"
                                step="0.01"
                                min="0"
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor={`month-${index}`}>Month:</label>
                            <select
                                id={`month-${index}`}
                                name="month"
                                value={entry.month}
                                onChange={(e) => handleChange(index, e)}
                                required
                            >
                                {monthOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="form-group">
                            <label htmlFor={`year-${index}`}>Year:</label>
                            <select
                                id={`year-${index}`}
                                name="year"
                                value={entry.year}
                                onChange={(e) => handleChange(index, e)}
                                required
                            >
                                <option value="">Select Year</option>
                                {yearOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {entries.length > 1 && (
                            <button
                                type="button"
                                onClick={() => removeEntry(index)}
                                className="remove-button"
                            >
                                Remove Entry
                            </button>
                        )}

                        <hr />
                    </div>
                ))}

                <button type="button" onClick={addEntry} className="add-button">
                    Add Another Entry
                </button>

                <button type="submit">Submit All Entries</button>
            </form>

            {message && <p className="message">{message}</p>}
        </div>
    );
};
    export default PerformancePlanData;

