// CompPlan.js

// Import necessary libraries and components
import React, { useState } from 'react';
import { NumericFormat } from 'react-number-format'; // Correct Import
import './CompPlan.css'; // Ensure this file exists for styling

const CompPlan = () => {
    const currentYear = new Date().getFullYear();

    // Define state for user input
    const [inputs, setInputs] = useState({
        basePaySalesManager: '',
        basePayTeamLeader: '',
        reputationScore: 'YES',
        salesEfficiency: '',
        oneSourcePenetration: '',
        esaPenetration: '',
        maintenancePenetration: '',
        ancillaryPenetration: '',
        commissionableGross: '',
        netProfit: '',
        newObjective: '',
        usedObjective: '',
        newFrontEndGross: '',
        usedFrontEndGross: '',
        accessorySales: '',
        financePerUnit: ''
    });

    const [weights, setWeights] = useState({
        newCarGrossWeight: '40',
        usedCarGrossWeight: '40',
        accessoriesWeight: '20',
        fpruWeight: '70',
        esaWeight: '15',
        maintenanceWeight: '10',
        ancillaryWeight: '5'
    });

    // Handle input changes for standard inputs
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputs((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    // Handle number format input changes (monetary inputs)
    const handleNumberInputChange = (name, value) => {
        setInputs((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    // Handle weight input changes (percentage inputs)
    const handleWeightInputChange = (name, value) => {
        setWeights((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleWeightChange = (e) => {
        const { name, value } = e.target;
        setWeights((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    // Helper function to format currency for display
    const formatCurrency = (value) => {
        if (isNaN(value)) return '$0.00';
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
    };

    // Calculation Functions
    const calculateSalesManagerCommission = () => {
        const commissionableGross = parseFloat(inputs.commissionableGross) || 0;
        return (commissionableGross * 0.0144).toFixed(2);
    };

    const calculateTeamLeaderCommission = () => {
        const commissionableGross = parseFloat(inputs.commissionableGross) || 0;
        return (commissionableGross * 0.0123).toFixed(2);
    };

    const calculateSalesManagerNetProfit = () => {
        const newObjective = parseFloat(inputs.newObjective);
        const netProfitInput = parseFloat(inputs.netProfit);
        if (isNaN(newObjective) || isNaN(netProfitInput)) {
            return '0.00';
        }
        if (newObjective < 1) {
            return ((netProfitInput * 0.0085) * -0.25).toFixed(2);
        } else {
            return (netProfitInput * 0.0085).toFixed(2);
        }
    };

    const calculateSalesManagerNewObjective = () => {
        const newObjectiveInput = parseFloat(inputs.newObjective);
        if (inputs.newObjective === '') {
            return '0.00';
        }
        if (newObjectiveInput < 110) {
            return (-1000).toFixed(2);
        }
        return '0.00';
    };

    const calculateTeamLeaderNewObjective = () => {
        const newObjectiveInput = parseFloat(inputs.newObjective);
        if (inputs.newObjective === '') {
            return '0.00';
        }
        if (newObjectiveInput < 1) {
            return (-1500).toFixed(2);
        } else if (newObjectiveInput < 110) {
            return (-1000).toFixed(2);
        }
        return '0.00';
    };

    const calculateSalesManagerUsedObjective = () => {
        const usedObjectiveInput = parseFloat(inputs.usedObjective);
        if (inputs.usedObjective === '') {
            return '0.00';
        }
        if (usedObjectiveInput >= 100) {
            return (500).toFixed(2);
        }
        return '0.00';
    };

    const calculateTeamLeaderUsedObjective = () => {
        const usedObjectiveInput = parseFloat(inputs.usedObjective);
        if (inputs.usedObjective === '') {
            return '0.00';
        }
        if (usedObjectiveInput >= 100) {
            return (250).toFixed(2);
        }
        return '0.00';
    };

    const calculateReputationScore = () => {
        return inputs.reputationScore === 'NO' ? -1000 : 0;
    };

    const calculateSalesEfficiency = () => {
        if (inputs.salesEfficiency === '') {
            return 0;
        }
        const efficiency = parseFloat(inputs.salesEfficiency);
        if (isNaN(efficiency)) return 0;
        return efficiency < 105 ? -500 : 0;
    };

    const calculateOneSourcePenetration = () => {
        if (inputs.oneSourcePenetration === '') {
            return 0;
        }
        const penetration = parseFloat(inputs.oneSourcePenetration);
        if (isNaN(penetration)) return 0;
        return penetration < 85 ? -500 : 0;
    };

    const calculateTotalCompensation = () => {
        const salesManagerComp = parseFloat(calculateSalesManagerCommission()) || 0;
        const teamLeaderComp = parseFloat(calculateTeamLeaderCommission()) || 0;
        const salesManagerNetProfit = parseFloat(calculateSalesManagerNetProfit()) || 0;
        const salesManagerNewObjective = parseFloat(calculateSalesManagerNewObjective()) || 0;
        const salesManagerUsedObjective = parseFloat(calculateSalesManagerUsedObjective()) || 0;
        const teamLeaderNewObjective = parseFloat(calculateTeamLeaderNewObjective()) || 0;
        const teamLeaderUsedObjective = parseFloat(calculateTeamLeaderUsedObjective()) || 0;
        
        const reputationScoreImpact = parseFloat(calculateReputationScore()) || 0;
        const salesEfficiencyImpact = parseFloat(calculateSalesEfficiency()) || 0;
        const oneSourcePenetrationImpact = parseFloat(calculateOneSourcePenetration()) || 0;

        // Calculate total compensation considering the impacts
        const totalSalesManager = salesManagerComp + salesManagerNetProfit + salesManagerNewObjective + salesManagerUsedObjective + reputationScoreImpact + salesEfficiencyImpact + oneSourcePenetrationImpact;
        const totalTeamLeader = teamLeaderComp + teamLeaderNewObjective + teamLeaderUsedObjective + reputationScoreImpact + salesEfficiencyImpact + oneSourcePenetrationImpact;

        return {
            salesManager: totalSalesManager,
            teamLeader: totalTeamLeader
        };
    };

    const totalCompensation = calculateTotalCompensation();

    // Calculate New Front-End Gross Average for Sales Manager
    const calculateNewFrontEndGrossAverageSalesManager = () => {
        const CPSalesManager = totalCompensation.salesManager || 0;
        const newFrontEndGrossInput = parseFloat(inputs.newFrontEndGross) || 0;
        const newCarGrossWeight = parseFloat(weights.newCarGrossWeight) / 100 || 0;

        if (newFrontEndGrossInput < 0) {
            return (CPSalesManager * 0.5) * newCarGrossWeight * 0.6;
        } else if (newFrontEndGrossInput < 1000) {
            return (CPSalesManager * 0.5) * newCarGrossWeight * 0.7;
        } else if (newFrontEndGrossInput < 1500) {
            return (CPSalesManager * 0.5) * newCarGrossWeight * 0.8;
        } else if (newFrontEndGrossInput < 2000) {
            return (CPSalesManager * 0.5) * newCarGrossWeight * 0.9;
        } else if (newFrontEndGrossInput >= 2000) {
            return (CPSalesManager * 0.5) * newCarGrossWeight;
        } else {
            return 0;
        }
    };

    // Calculate New Front-End Gross Average for Team Leader
    const calculateNewFrontEndGrossAverageTeamLeader = () => {
        const CPTeamLeader = totalCompensation.teamLeader || 0;
        const newFrontEndGrossInput = parseFloat(inputs.newFrontEndGross) || 0;
        const newCarGrossWeight = parseFloat(weights.newCarGrossWeight) / 100 || 0;

        if (newFrontEndGrossInput < 0) {
            return (CPTeamLeader * 0.5) * newCarGrossWeight * 0.6;
        } else if (newFrontEndGrossInput < 1000) {
            return (CPTeamLeader * 0.5) * newCarGrossWeight * 0.7;
        } else if (newFrontEndGrossInput < 1500) {
            return (CPTeamLeader * 0.5) * newCarGrossWeight * 0.8;
        } else if (newFrontEndGrossInput < 2000) {
            return (CPTeamLeader * 0.5) * newCarGrossWeight * 0.9;
        } else if (newFrontEndGrossInput >= 2000) {
            return (CPTeamLeader * 0.5) * newCarGrossWeight;
        } else {
            return 0;
        }
    };

    // Calculate Used Front-End Gross Average for Sales Manager
    const calculateUsedFrontEndGrossAverageSalesManager = () => {
        const CPSalesManager = totalCompensation.salesManager || 0;
        const usedFrontEndGrossInput = parseFloat(inputs.usedFrontEndGross) || 0;
        const usedCarGrossWeight = parseFloat(weights.usedCarGrossWeight) / 100 || 0;

        if (usedFrontEndGrossInput < 1450) {
            return (CPSalesManager * 0.5) * usedCarGrossWeight * 0.6;
        } else if (usedFrontEndGrossInput < 1550) {
            return (CPSalesManager * 0.5) * usedCarGrossWeight * 0.7;
        } else if (usedFrontEndGrossInput < 1650) {
            return (CPSalesManager * 0.5) * usedCarGrossWeight * 0.8;
        } else if (usedFrontEndGrossInput < 1750) {
            return (CPSalesManager * 0.5) * usedCarGrossWeight * 0.9;
        } else if (usedFrontEndGrossInput >= 1750) {
            return (CPSalesManager * 0.5) * usedCarGrossWeight;
        } else {
            return 0;
        }
    };

    // Calculate Used Front-End Gross Average for Team Leader
    const calculateUsedFrontEndGrossAverageTeamLeader = () => {
        const CPTeamLeader = totalCompensation.teamLeader || 0;
        const usedFrontEndGrossInput = parseFloat(inputs.usedFrontEndGross) || 0;
        const usedCarGrossWeight = parseFloat(weights.usedCarGrossWeight) / 100 || 0;

        if (usedFrontEndGrossInput < 1450) {
            return (CPTeamLeader * 0.5) * usedCarGrossWeight * 0.6;
        } else if (usedFrontEndGrossInput < 1550) {
            return (CPTeamLeader * 0.5) * usedCarGrossWeight * 0.7;
        } else if (usedFrontEndGrossInput < 1650) {
            return (CPTeamLeader * 0.5) * usedCarGrossWeight * 0.8;
        } else if (usedFrontEndGrossInput < 1750) {
            return (CPTeamLeader * 0.5) * usedCarGrossWeight * 0.9;
        } else if (usedFrontEndGrossInput >= 1750) {
            return (CPTeamLeader * 0.5) * usedCarGrossWeight;
        } else {
            return 0;
        }
    };

    // Calculate Accessory Sales for Sales Manager
    const calculateAccessorySalesAverageSalesManager = () => {
        const CPSalesManager = totalCompensation.salesManager || 0;
        const accessorySalesInput = parseFloat(inputs.accessorySales) || 0;
        const accessoriesWeight = parseFloat(weights.accessoriesWeight) / 100 || 0;

        if (accessorySalesInput < 38000) {
            return (CPSalesManager * 0.5) * accessoriesWeight * 0.6;
        } else if (accessorySalesInput < 45000) {
            return (CPSalesManager * 0.5) * accessoriesWeight * 0.7;
        } else if (accessorySalesInput < 53000) {
            return (CPSalesManager * 0.5) * accessoriesWeight * 0.8;
        } else if (accessorySalesInput < 60000) {
            return (CPSalesManager * 0.5) * accessoriesWeight * 0.9;
        } else if (accessorySalesInput >= 60000) {
            return (CPSalesManager * 0.5) * accessoriesWeight;
        } else {
            return 0;
        }
    };

    // Calculate Accessory Sales for Team Leader
    const calculateAccessorySalesAverageTeamLeader = () => {
        const CPTeamLeader = totalCompensation.teamLeader || 0;
        const accessorySalesInput = parseFloat(inputs.accessorySales) || 0;
        const accessoriesWeight = parseFloat(weights.accessoriesWeight) / 100 || 0;

        if (accessorySalesInput < 38000) {
            return (CPTeamLeader * 0.5) * accessoriesWeight * 0.6;
        } else if (accessorySalesInput < 45000) {
            return (CPTeamLeader * 0.5) * accessoriesWeight * 0.7;
        } else if (accessorySalesInput < 53000) {
            return (CPTeamLeader * 0.5) * accessoriesWeight * 0.8;
        } else if (accessorySalesInput < 60000) {
            return (CPTeamLeader * 0.5) * accessoriesWeight * 0.9;
        } else if (accessorySalesInput >= 60000) {
            return (CPTeamLeader * 0.5) * accessoriesWeight;
        } else {
            return 0;
        }
    };

    // Calculate Finance Per Retail Unit Average for Sales Manager
    const calculateFinancePerRetailUnitAverageSalesManager = () => {
        const CPSalesManager = totalCompensation.salesManager || 0;
        const financePerUnitInput = parseFloat(inputs.financePerUnit) || 0;
        const fpruWeight = parseFloat(weights.fpruWeight) / 100 || 0;

        if (financePerUnitInput < 1484) {
            return (CPSalesManager * 0.5) * fpruWeight * 0.6;
        } else if (financePerUnitInput < 1584) {
            return (CPSalesManager * 0.5) * fpruWeight * 0.7;
        } else if (financePerUnitInput < 1684) {
            return (CPSalesManager * 0.5) * fpruWeight * 0.8;
        } else if (financePerUnitInput < 1784) {
            return (CPSalesManager * 0.5) * fpruWeight * 0.9;
        } else if (financePerUnitInput >= 1784) {
            return (CPSalesManager * 0.5) * fpruWeight;
        } else {
            return 0;
        }
    };

    // Calculate Finance Per Retail Unit Average for Team Leader
    const calculateFinancePerRetailUnitAverageTeamLeader = () => {
        const CPTeamLeader = totalCompensation.teamLeader || 0;
        const financePerUnitInput = parseFloat(inputs.financePerUnit) || 0;
        const fpruWeight = parseFloat(weights.fpruWeight) / 100 || 0;

        if (financePerUnitInput < 1484) {
            return (CPTeamLeader * 0.5) * fpruWeight * 0.6;
        } else if (financePerUnitInput < 1584) {
            return (CPTeamLeader * 0.5) * fpruWeight * 0.7;
        } else if (financePerUnitInput < 1684) {
            return (CPTeamLeader * 0.5) * fpruWeight * 0.8;
        } else if (financePerUnitInput < 1784) {
            return (CPTeamLeader * 0.5) * fpruWeight * 0.9;
        } else if (financePerUnitInput >= 1784) {
            return (CPTeamLeader * 0.5) * fpruWeight;
        } else {
            return 0;
        }
    };

    // Calculate ESA Penetration for Sales Manager
    const calculateEsaPenetrationSalesManager = () => {
        const CPSalesManager = totalCompensation.salesManager || 0;
        const esaPenetrationInput = parseFloat(inputs.esaPenetration) / 100 || 0; // Convert percentage to decimal
        const esaWeight = parseFloat(weights.esaWeight) / 100 || 0;

        if (esaPenetrationInput < 0.65) {
            return (CPSalesManager * 0.5) * esaWeight * 0.5;
        } else if (esaPenetrationInput < 0.66) {
            return (CPSalesManager * 0.5) * esaWeight * 0.8;
        } else if (esaPenetrationInput < 0.67) {
            return (CPSalesManager * 0.5) * esaWeight * 0.9;
        } else if (esaPenetrationInput >= 0.67) {
            return (CPSalesManager * 0.5) * esaWeight;
        } else {
            return 0;
        }
    };

    // Calculate ESA Penetration for Team Leader
    const calculateEsaPenetrationTeamLeader = () => {
        const CPTeamLeader = totalCompensation.teamLeader || 0;
        const esaPenetrationInput = parseFloat(inputs.esaPenetration) / 100 || 0; // Convert percentage to decimal
        const esaWeight = parseFloat(weights.esaWeight) / 100 || 0;

        if (esaPenetrationInput < 0.65) {
            return (CPTeamLeader * 0.5) * esaWeight * 0.5;
        } else if (esaPenetrationInput < 0.66) {
            return (CPTeamLeader * 0.5) * esaWeight * 0.8;
        } else if (esaPenetrationInput < 0.67) {
            return (CPTeamLeader * 0.5) * esaWeight * 0.9;
        } else if (esaPenetrationInput >= 0.67) {
            return (CPTeamLeader * 0.5) * esaWeight;
        } else {
            return 0;
        }
    };

    // Calculate Maintenance Agreement Penetration for Sales Manager
    const calculateMaintenancePenetrationSalesManager = () => {
        const CPSalesManager = totalCompensation.salesManager || 0;
        const maintenancePenetrationInput = parseFloat(inputs.maintenancePenetration) / 100 || 0; // Convert percentage to decimal
        const maintenanceWeight = parseFloat(weights.maintenanceWeight) / 100 || 0;

        if (maintenancePenetrationInput < 0.57) {
            return (CPSalesManager * 0.5) * maintenanceWeight * 0.6;
        } else if (maintenancePenetrationInput < 0.59) {
            return (CPSalesManager * 0.5) * maintenanceWeight * 0.8;
        } else if (maintenancePenetrationInput >= 0.59) {
            return (CPSalesManager * 0.5) * maintenanceWeight;
        } else {
            return 0;
        }
    };

    // Calculate Maintenance Agreement Penetration for Team Leader
    const calculateMaintenancePenetrationTeamLeader = () => {
        const CPTeamLeader = totalCompensation.teamLeader || 0;
        const maintenancePenetrationInput = parseFloat(inputs.maintenancePenetration) / 100 || 0; // Convert percentage to decimal
        const maintenanceWeight = parseFloat(weights.maintenanceWeight) / 100 || 0;

        if (maintenancePenetrationInput < 0.57) {
            return (CPTeamLeader * 0.5) * maintenanceWeight * 0.6;
        } else if (maintenancePenetrationInput < 0.59) {
            return (CPTeamLeader * 0.5) * maintenanceWeight * 0.8;
        } else if (maintenancePenetrationInput >= 0.59) {
            return (CPTeamLeader * 0.5) * maintenanceWeight;
        } else {
            return 0;
        }
    };

    // Calculate Ancillary Products Penetration for Sales Manager
    const calculateAncillaryPenetrationSalesManager = () => {
        const CPSalesManager = totalCompensation.salesManager || 0;
        const ancillaryPenetrationInput = parseFloat(inputs.ancillaryPenetration) / 100 || 0; // Convert percentage to decimal
        const ancillaryWeight = parseFloat(weights.ancillaryWeight) / 100 || 0;

        if (ancillaryPenetrationInput < 0.52) {
            return (CPSalesManager * 0.5) * ancillaryWeight * 0.6;
        } else if (ancillaryPenetrationInput < 0.54) {
            return (CPSalesManager * 0.5) * ancillaryWeight * 0.8;
        } else if (ancillaryPenetrationInput >= 0.54) {
            return (CPSalesManager * 0.5) * ancillaryWeight;
        } else {
            return 0;
        }
    };

    // Calculate Ancillary Products Penetration for Team Leader
    const calculateAncillaryPenetrationTeamLeader = () => {
        const CPTeamLeader = totalCompensation.teamLeader || 0;
        const ancillaryPenetrationInput = parseFloat(inputs.ancillaryPenetration) / 100 || 0; // Convert percentage to decimal
        const ancillaryWeight = parseFloat(weights.ancillaryWeight) / 100 || 0;

        if (ancillaryPenetrationInput < 0.52) {
            return (CPTeamLeader * 0.5) * ancillaryWeight * 0.6;
        } else if (ancillaryPenetrationInput < 0.54) {
            return (CPTeamLeader * 0.5) * ancillaryWeight * 0.8;
        } else if (ancillaryPenetrationInput >= 0.54) {
            return (CPTeamLeader * 0.5) * ancillaryWeight;
        } else {
            return 0;
        }
    };

    // Calculate all Commission Pool Weighted Pay Out variables
    const newFrontEndGrossAverageSalesManager = calculateNewFrontEndGrossAverageSalesManager();
    const newFrontEndGrossAverageTeamLeader = calculateNewFrontEndGrossAverageTeamLeader();
    const usedFrontEndGrossAverageSalesManager = calculateUsedFrontEndGrossAverageSalesManager();
    const usedFrontEndGrossAverageTeamLeader = calculateUsedFrontEndGrossAverageTeamLeader();
    const accessorySalesAverageSalesManager = calculateAccessorySalesAverageSalesManager();
    const accessorySalesAverageTeamLeader = calculateAccessorySalesAverageTeamLeader();
    const financePerRetailUnitAverageSalesManager = calculateFinancePerRetailUnitAverageSalesManager();
    const financePerRetailUnitAverageTeamLeader = calculateFinancePerRetailUnitAverageTeamLeader();
    const esaPenetrationSalesManager = calculateEsaPenetrationSalesManager();
    const esaPenetrationTeamLeader = calculateEsaPenetrationTeamLeader();
    const maintenancePenetrationSalesManager = calculateMaintenancePenetrationSalesManager();
    const maintenancePenetrationTeamLeader = calculateMaintenancePenetrationTeamLeader();
    const ancillaryPenetrationSalesManager = calculateAncillaryPenetrationSalesManager();
    const ancillaryPenetrationTeamLeader = calculateAncillaryPenetrationTeamLeader();

    // Calculate sums for Commission Pool Weighted Pay Out
    const commissionPoolWeightedSumSalesManager =
        (parseFloat(newFrontEndGrossAverageSalesManager) || 0) +
        (parseFloat(usedFrontEndGrossAverageSalesManager) || 0) +
        (parseFloat(accessorySalesAverageSalesManager) || 0) +
        (parseFloat(financePerRetailUnitAverageSalesManager) || 0) +
        (parseFloat(esaPenetrationSalesManager) || 0) +
        (parseFloat(maintenancePenetrationSalesManager) || 0) +
        (parseFloat(ancillaryPenetrationSalesManager) || 0);

    const commissionPoolWeightedSumTeamLeader =
        (parseFloat(newFrontEndGrossAverageTeamLeader) || 0) +
        (parseFloat(usedFrontEndGrossAverageTeamLeader) || 0) +
        (parseFloat(accessorySalesAverageTeamLeader) || 0) +
        (parseFloat(financePerRetailUnitAverageTeamLeader) || 0) +
        (parseFloat(esaPenetrationTeamLeader) || 0) +
        (parseFloat(maintenancePenetrationTeamLeader) || 0) +
        (parseFloat(ancillaryPenetrationTeamLeader) || 0);

    // Render Component
    return (
        <div className="comp-plan-container">
            {/* <h1>Comp Plan</h1> */}

            {/* Base Pay Segment */}
            <h2>Base Pay</h2>
            <table className="comp-plan-table">
                <thead>
                    <tr>
                        <th>Variable</th>
                        <th>Input ($)</th>
                        <th>Sales Manager ($)</th>
                        <th>Team Leader ($)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Base Pay</td>
                        <td>
                            <NumericFormat
                                thousandSeparator={true}
                                prefix={'$'}
                                className="input-cell"
                                placeholder="Sales Manager"
                                value={inputs.basePaySalesManager}
                                onValueChange={(values) => {
                                    const { value } = values;
                                    handleNumberInputChange('basePaySalesManager', value);
                                }}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                allowNegative={false}
                            />
                            <NumericFormat
                                thousandSeparator={true}
                                prefix={'$'}
                                className="input-cell"
                                placeholder="Team Leader"
                                value={inputs.basePayTeamLeader}
                                onValueChange={(values) => {
                                    const { value } = values;
                                    handleNumberInputChange('basePayTeamLeader', value);
                                }}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                allowNegative={false}
                            />
                        </td>
                        <td>{formatCurrency(parseFloat(inputs.basePaySalesManager) || 0)}</td>
                        <td>{formatCurrency(parseFloat(inputs.basePayTeamLeader) || 0)}</td>
                    </tr>
                </tbody>
            </table>

            {/* Weight Input Segment */}
            <h2>Weight Inputs</h2>
            <table className="comp-plan-table">
                <thead>
                    <tr>
                        <th>Variable</th>
                        <th>Weight (%)</th>
                    </tr>
                </thead>
                <tbody>
                    {/* Replace standard inputs with NumericFormat for percentage weights */}
                    <tr>
                        <td>New Car Gross Average Weight</td>
                        <td>
                            <NumericFormat
                                thousandSeparator={false}
                                suffix={'%'}
                                className="input-cell"
                                placeholder='e.g. 40%'
                                value={weights.newCarGrossWeight}
                                onValueChange={(values) => {
                                    const { value } = values;
                                    handleWeightInputChange('newCarGrossWeight', value);
                                }}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                allowNegative={false}
                                isAllowed={(values) => {
                                    const { floatValue } = values;
                                    return floatValue >= 0 && floatValue <= 100;
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Used Car Gross Average Weight</td>
                        <td>
                            <NumericFormat
                                thousandSeparator={false}
                                suffix={'%'}
                                className="input-cell"
                                placeholder='e.g. 40%'
                                value={weights.usedCarGrossWeight}
                                onValueChange={(values) => {
                                    const { value } = values;
                                    handleWeightInputChange('usedCarGrossWeight', value);
                                }}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                allowNegative={false}
                                isAllowed={(values) => {
                                    const { floatValue } = values;
                                    return floatValue >= 0 && floatValue <= 100;
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Accessories Weight</td>
                        <td>
                            <NumericFormat
                                thousandSeparator={false}
                                suffix={'%'}
                                className="input-cell"
                                placeholder='e.g. 20%'
                                value={weights.accessoriesWeight}
                                onValueChange={(values) => {
                                    const { value } = values;
                                    handleWeightInputChange('accessoriesWeight', value);
                                }}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                allowNegative={false}
                                isAllowed={(values) => {
                                    const { floatValue } = values;
                                    return floatValue >= 0 && floatValue <= 100;
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>FPRU Average Weight</td>
                        <td>
                            <NumericFormat
                                thousandSeparator={false}
                                suffix={'%'}
                                className="input-cell"
                                placeholder='e.g. 70%'
                                value={weights.fpruWeight}
                                onValueChange={(values) => {
                                    const { value } = values;
                                    handleWeightInputChange('fpruWeight', value);
                                }}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                allowNegative={false}
                                isAllowed={(values) => {
                                    const { floatValue } = values;
                                    return floatValue >= 0 && floatValue <= 100;
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>ESA Average Weight</td>
                        <td>
                            <NumericFormat
                                thousandSeparator={false}
                                suffix={'%'}
                                className="input-cell"
                                placeholder='e.g. 15%'
                                value={weights.esaWeight}
                                onValueChange={(values) => {
                                    const { value } = values;
                                    handleWeightInputChange('esaWeight', value);
                                }}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                allowNegative={false}
                                isAllowed={(values) => {
                                    const { floatValue } = values;
                                    return floatValue >= 0 && floatValue <= 100;
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Maintenance Agreement Penetration Weight</td>
                        <td>
                            <NumericFormat
                                thousandSeparator={false}
                                suffix={'%'}
                                className="input-cell"
                                placeholder='e.g. 10%'
                                value={weights.maintenanceWeight}
                                onValueChange={(values) => {
                                    const { value } = values;
                                    handleWeightInputChange('maintenanceWeight', value);
                                }}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                allowNegative={false}
                                isAllowed={(values) => {
                                    const { floatValue } = values;
                                    return floatValue >= 0 && floatValue <= 100;
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Ancillary Products Penetration Weight</td>
                        <td>
                            <NumericFormat
                                thousandSeparator={false}
                                suffix={'%'}
                                className="input-cell"
                                placeholder='e.g. 5%'
                                value={weights.ancillaryWeight}
                                onValueChange={(values) => {
                                    const { value } = values;
                                    handleWeightInputChange('ancillaryWeight', value);
                                }}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                allowNegative={false}
                                isAllowed={(values) => {
                                    const { floatValue } = values;
                                    return floatValue >= 0 && floatValue <= 100;
                                }}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>

            {/* Commissionable Pool Segment */}
            <h2>
                Commissionable Pool
                <span className="section-totals">
                    &nbsp;&nbsp;|&nbsp;&nbsp;Total Sales Manager: {formatCurrency(totalCompensation.salesManager)}
                </span>
                <span className="section-totals">
                    &nbsp;&nbsp;|&nbsp;&nbsp;Total Team Leader: {formatCurrency(totalCompensation.teamLeader)}
                </span>
            </h2>
            <table className="comp-plan-table">
                <thead>
                    <tr>
                        <th>Variable</th>
                        <th>Input ($)</th>
                        <th>Sales Manager ($)</th>
                        <th>Team Leader ($)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Commissionable Gross ($)</td>
                        <td>
                            <NumericFormat
                                thousandSeparator={true}
                                prefix={'$'}
                                className="input-cell"
                                placeholder="e.g. $100,000.00"
                                value={inputs.commissionableGross}
                                onValueChange={(values) => {
                                    const { value } = values;
                                    handleNumberInputChange('commissionableGross', value);
                                }}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                allowNegative={false}
                            />
                        </td>
                        <td>{formatCurrency(parseFloat(calculateSalesManagerCommission()) || 0)}</td>
                        <td>{formatCurrency(parseFloat(calculateTeamLeaderCommission()) || 0)}</td>
                    </tr>
                    <tr>
                        <td>Net Profit ($)</td>
                        <td>
                            <NumericFormat
                                thousandSeparator={true}
                                prefix={'$'}
                                className="input-cell"
                                placeholder="e.g. $20,000.00"
                                value={inputs.netProfit}
                                onValueChange={(values) => {
                                    const { value } = values;
                                    handleNumberInputChange('netProfit', value);
                                }}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                allowNegative={false}
                            />
                        </td>
                        <td>{formatCurrency(parseFloat(calculateSalesManagerNetProfit()) || 0)}</td>
                        <td>{/* If needed */}</td>
                    </tr>
                    <tr>
                        <td>New Car Objective (%)</td>
                        <td>
                            <NumericFormat
                                thousandSeparator={false}
                                suffix={'%'}
                                className="input-cell"
                                placeholder="e.g. 102.5%"
                                value={inputs.newObjective}
                                onValueChange={(values) => {
                                    const { value } = values;
                                    handleNumberInputChange('newObjective', value);
                                }}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                allowNegative={false}
                                isAllowed={(values) => {
                                    const { floatValue } = values;
                                    return floatValue >= 0 && floatValue <= 200; // Adjust max as needed
                                }}
                            />
                        </td>
                        <td>{formatCurrency(parseFloat(calculateSalesManagerNewObjective()) || 0)}</td>
                        <td>{formatCurrency(parseFloat(calculateTeamLeaderNewObjective()) || 0)}</td>
                    </tr>
                    <tr>
                        <td>Used Car Objective (%)</td>
                        <td>
                            <NumericFormat
                                thousandSeparator={false}
                                suffix={'%'}
                                className="input-cell"
                                placeholder="e.g. 102.5%"
                                value={inputs.usedObjective}
                                onValueChange={(values) => {
                                    const { value } = values;
                                    handleNumberInputChange('usedObjective', value);
                                }}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                allowNegative={false}
                                isAllowed={(values) => {
                                    const { floatValue } = values;
                                    return floatValue >= 0 && floatValue <= 200; // Adjust max as needed
                                }}
                            />
                        </td>
                        <td>{formatCurrency(parseFloat(calculateSalesManagerUsedObjective()) || 0)}</td>
                        <td>{formatCurrency(parseFloat(calculateTeamLeaderUsedObjective()) || 0)}</td>
                    </tr>
                    {/* New Calculations */}
                    <tr>
                        <td>Reputation.com Score</td>
                        <td>
                            <select
                                name="reputationScore"
                                className="input-cell"
                                value={inputs.reputationScore}
                                onChange={handleInputChange}
                            >
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </td>
                        <td>{formatCurrency(calculateReputationScore())}</td>
                        <td>{formatCurrency(calculateReputationScore())}</td>
                    </tr>
                    <tr>
                        <td>Sales Efficiency (%)</td>
                        <td>
                            <NumericFormat
                                thousandSeparator={false}
                                suffix={'%'}
                                className="input-cell"
                                placeholder="e.g. 103.2%"
                                value={inputs.salesEfficiency}
                                onValueChange={(values) => {
                                    const { value } = values;
                                    handleNumberInputChange('salesEfficiency', value);
                                }}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                allowNegative={false}
                                isAllowed={(values) => {
                                    const { floatValue } = values;
                                    return floatValue >= 0 && floatValue <= 200; // Adjust max as needed
                                }}
                            />
                        </td>
                        <td>{formatCurrency(calculateSalesEfficiency())}</td>
                        <td>{formatCurrency(calculateSalesEfficiency())}</td>
                    </tr>
                    <tr>
                        <td>One Source Penetration (%)</td>
                        <td>
                            <NumericFormat
                                thousandSeparator={false}
                                suffix={'%'}
                                className="input-cell"
                                placeholder="e.g. 84.9%"
                                value={inputs.oneSourcePenetration}
                                onValueChange={(values) => {
                                    const { value } = values;
                                    handleNumberInputChange('oneSourcePenetration', value);
                                }}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                allowNegative={false}
                                isAllowed={(values) => {
                                    const { floatValue } = values;
                                    return floatValue >= 0 && floatValue <= 200; // Adjust max as needed
                                }}
                            />
                        </td>
                        <td>{formatCurrency(calculateOneSourcePenetration())}</td>
                        <td>{formatCurrency(calculateOneSourcePenetration())}</td>
                    </tr>
                </tbody>
            </table>

            {/* Commission Pool Weighted Pay Out Segment */}
            <h2>
                Commission Pool Weighted Pay Out
                <span className="section-totals">
                    &nbsp;&nbsp;|&nbsp;&nbsp;Total Sales Manager: {formatCurrency(commissionPoolWeightedSumSalesManager)}
                </span>
                <span className="section-totals">
                    &nbsp;&nbsp;|&nbsp;&nbsp;Total Team Leader: {formatCurrency(commissionPoolWeightedSumTeamLeader)}
                </span>
            </h2>
            <table className="comp-plan-table">
                <thead>
                    <tr>
                        <th>Variable</th>
                        <th>Input ($ or %)</th>
                        <th>Sales Manager ($)</th>
                        <th>Team Leader ($)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>New Front-End Gross Average ($)</td>
                        <td>
                            <NumericFormat
                                thousandSeparator={true}
                                prefix={'$'}
                                className="input-cell"
                                placeholder='e.g. $1,000.00'
                                value={inputs.newFrontEndGross}
                                onValueChange={(values) => {
                                    const { value } = values;
                                    handleNumberInputChange('newFrontEndGross', value);
                                }}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                allowNegative={false}
                            />
                        </td>
                        <td>{formatCurrency(newFrontEndGrossAverageSalesManager)}</td>
                        <td>{formatCurrency(newFrontEndGrossAverageTeamLeader)}</td>
                    </tr>
                    <tr>
                        <td>Used Front-End Gross Average ($)</td>
                        <td>
                            <NumericFormat
                                thousandSeparator={true}
                                prefix={'$'}
                                className="input-cell"
                                placeholder='e.g. $1,000.00'
                                value={inputs.usedFrontEndGross}
                                onValueChange={(values) => {
                                    const { value } = values;
                                    handleNumberInputChange('usedFrontEndGross', value);
                                }}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                allowNegative={false}
                            />
                        </td>
                        <td>{formatCurrency(usedFrontEndGrossAverageSalesManager)}</td>
                        <td>{formatCurrency(usedFrontEndGrossAverageTeamLeader)}</td>
                    </tr>
                    <tr>
                        <td>Accessory Sales ($)</td>
                        <td>
                            <NumericFormat
                                thousandSeparator={true}
                                prefix={'$'}
                                className="input-cell"
                                placeholder='e.g. $20,000.00'
                                value={inputs.accessorySales}
                                onValueChange={(values) => {
                                    const { value } = values;
                                    handleNumberInputChange('accessorySales', value);
                                }}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                allowNegative={false}
                            />
                        </td>
                        <td>{formatCurrency(accessorySalesAverageSalesManager)}</td>
                        <td>{formatCurrency(accessorySalesAverageTeamLeader)}</td>
                    </tr>
                    <tr>
                        <td>Finance Per Retail Unit Average ($)</td>
                        <td>
                            <NumericFormat
                                thousandSeparator={true}
                                prefix={'$'}
                                className="input-cell"
                                placeholder='e.g. $2,000.00'
                                value={inputs.financePerUnit}
                                onValueChange={(values) => {
                                    const { value } = values;
                                    handleNumberInputChange('financePerUnit', value);
                                }}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                allowNegative={false}
                            />
                        </td>
                        <td>{formatCurrency(financePerRetailUnitAverageSalesManager)}</td>
                        <td>{formatCurrency(financePerRetailUnitAverageTeamLeader)}</td>
                    </tr>
                    <tr>
                        <td>ESA Penetration (%)</td>
                        <td>
                            <NumericFormat
                                thousandSeparator={false}
                                suffix={'%'}
                                className="input-cell"
                                placeholder='e.g. 33.33%'
                                value={inputs.esaPenetration}
                                onValueChange={(values) => {
                                    const { value } = values;
                                    handleNumberInputChange('esaPenetration', value);
                                }}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                allowNegative={false}
                                isAllowed={(values) => {
                                    const { floatValue } = values;
                                    return floatValue >= 0 && floatValue <= 100;
                                }}
                            />
                        </td>
                        <td>{formatCurrency(esaPenetrationSalesManager)}</td>
                        <td>{formatCurrency(esaPenetrationTeamLeader)}</td>
                    </tr>
                    <tr>
                        <td>Maintenance Agreement Penetration (%)</td>
                        <td>
                            <NumericFormat
                                thousandSeparator={false}
                                suffix={'%'}
                                className="input-cell"
                                placeholder='e.g. 50.60%'
                                value={inputs.maintenancePenetration}
                                onValueChange={(values) => {
                                    const { value } = values;
                                    handleNumberInputChange('maintenancePenetration', value);
                                }}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                allowNegative={false}
                                isAllowed={(values) => {
                                    const { floatValue } = values;
                                    return floatValue >= 0 && floatValue <= 100;
                                }}
                            />
                        </td>
                        <td>{formatCurrency(maintenancePenetrationSalesManager)}</td>
                        <td>{formatCurrency(maintenancePenetrationTeamLeader)}</td>
                    </tr>
                    <tr>
                        <td>Ancillary Products Penetration (%)</td>
                        <td>
                            <NumericFormat
                                thousandSeparator={false}
                                suffix={'%'}
                                className="input-cell"
                                placeholder='e.g. 47.27%'
                                value={inputs.ancillaryPenetration}
                                onValueChange={(values) => {
                                    const { value } = values;
                                    handleNumberInputChange('ancillaryPenetration', value);
                                }}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                allowNegative={false}
                                isAllowed={(values) => {
                                    const { floatValue } = values;
                                    return floatValue >= 0 && floatValue <= 100;
                                }}
                            />
                        </td>
                        <td>{formatCurrency(ancillaryPenetrationSalesManager)}</td>
                        <td>{formatCurrency(ancillaryPenetrationTeamLeader)}</td>
                    </tr>
                </tbody>
            </table>

            {/* Total Compensation Segment */}
            <h2>Total Compensation</h2>
            <table className="comp-plan-table">
                <thead>
                    <tr>
                        <th>Variable</th>
                        <th>Sales Manager ($)</th>
                        <th>Team Leader ($)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Total Compensation</td>
                        <td>{formatCurrency(totalCompensation.salesManager)}</td>
                        <td>{formatCurrency(totalCompensation.teamLeader)}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );

};

export default CompPlan;
