import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import "./InventoryTradeForm.css"; // optional styling

function InventoryTradeForm() {
  // -------------------------------------------------------------------
  // 1) Component State
  // -------------------------------------------------------------------
  const [user, setUser] = useState(null);
  const [localStore, setLocalStore] = useState("");
  const [remoteStore, setRemoteStore] = useState("");

  const [localInventory, setLocalInventory] = useState([]);
  const [remoteInventory, setRemoteInventory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Filter State
  const [filterSaleType, setFilterSaleType] = useState("");
  const [filterMake, setFilterMake] = useState("");
  const [filterModel, setFilterModel] = useState("");
  const [filterYear, setFilterYear] = useState("");

  // Image Carousel State
  const [localImageIndexes, setLocalImageIndexes] = useState({});
  const [remoteImageIndexes, setRemoteImageIndexes] = useState({});

  // For normal "side-by-side" selection
  const [selectedLocalVehicle, setSelectedLocalVehicle] = useState(null);
  const [selectedRemoteVehicle, setSelectedRemoteVehicle] = useState(null);

  // For "Review" mode if we come in with ?request_id=XYZ
  const [searchParams] = useSearchParams();
  const requestId = searchParams.get("request_id");

  // We'll store the "review" vehicles (local vs. remote) here
  const [reviewLocalVehicle, setReviewLocalVehicle] = useState(null);
  const [reviewRemoteVehicle, setReviewRemoteVehicle] = useState(null);

  // -------------------------------------------------------------------
  // 2) Fetch user details on mount
  // -------------------------------------------------------------------
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem("accessToken");
        if (!token) {
          console.error("No token found");
          return;
        }

        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/getUserDetails`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          console.error("Failed to fetch user details");
          return;
        }

        const userData = await response.json();
        setUser(userData);

        // Set localStore from defaultDatasetId or first in dataset_mapping
        if (userData.defaultDatasetId) {
          setLocalStore(userData.defaultDatasetId);
        } else if (
          userData.dataset_mapping &&
          userData.dataset_mapping.length > 0
        ) {
          setLocalStore(userData.dataset_mapping[0].datasetId);
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchUserDetails();
  }, []);

  // -------------------------------------------------------------------
  // 3) If we have a request_id => fetch comparison data (Review Mode)
  // -------------------------------------------------------------------
  useEffect(() => {
    if (!requestId) return; // not in "review" mode

    const fetchComparisonData = async () => {
      try {
        const token = localStorage.getItem("accessToken");
        if (!token) return;

        const compareUrl = `${process.env.REACT_APP_BACKEND_URL}/api/get-trade-request-compare?request_id=${requestId}`;
        const resp = await fetch(compareUrl, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (resp.ok) {
          const data = await resp.json();
          // data = { localVehicle: {...}, remoteVehicle: {...} }

          // Flatten date fields so we don't have { value: "..." } in the UI
          if (data.localVehicle) flattenDateFields(data.localVehicle);
          if (data.remoteVehicle) flattenDateFields(data.remoteVehicle);

          setReviewLocalVehicle(data.localVehicle);
          setReviewRemoteVehicle(data.remoteVehicle);
        } else {
          console.error("Failed to fetch trade comparison data");
        }
      } catch (err) {
        console.error("Error fetching trade comparison:", err);
      }
    };

    fetchComparisonData();
  }, [requestId]);

  // -------------------------------------------------------------------
  // 4) Flatten BigQuery date fields
  // -------------------------------------------------------------------
  const flattenDateFields = (row) => {
    if (!row) return row; // guard
    if (row.stock_in_date && typeof row.stock_in_date === "object") {
      row.stock_in_date = row.stock_in_date.value;
    }
    if (row.submitted_at && typeof row.submitted_at === "object") {
      row.submitted_at = row.submitted_at.value;
    }
    return row;
  };

  // -------------------------------------------------------------------
  // 5) Fetch local/remote inventories (normal usage)
  // -------------------------------------------------------------------
  useEffect(() => {
    const fetchInventories = async () => {
      if (!localStore || !remoteStore) return;

      setIsLoading(true);
      try {
        const token = localStorage.getItem("accessToken");
        if (!token) {
          console.error("No token found");
          setIsLoading(false);
          return;
        }

        // local store inventory
        const localUrl = `${process.env.REACT_APP_BACKEND_URL}/api/fetch-inventory-by-store?store=${localStore}`;
        const localResp = await fetch(localUrl, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const localData = localResp.ok ? await localResp.json() : [];

        // remote store inventory
        const remoteUrl = `${process.env.REACT_APP_BACKEND_URL}/api/fetch-inventory-by-store?store=${remoteStore}`;
        const remoteResp = await fetch(remoteUrl, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const remoteData = remoteResp.ok ? await remoteResp.json() : [];

        // Flatten date fields
        localData.forEach(flattenDateFields);
        remoteData.forEach(flattenDateFields);

        // Convert numeric fields
        const fixFields = (arr) => {
          arr.forEach((v) => {
            v.sale_price = v.sale_price ? Number(v.sale_price) : 0;
            v.inventory_cost = v.inventory_cost ? Number(v.inventory_cost) : 0;
          });
        };
        fixFields(localData);
        fixFields(remoteData);

        // Sort them
        const sortFn = (a, b) => {
          const makeCmp = (a.make || "").localeCompare(b.make || "");
          if (makeCmp !== 0) return makeCmp;

          const modelCmp = (a.model || "").localeCompare(b.model || "");
          if (modelCmp !== 0) return modelCmp;

          const yearA = a.model_year || 0;
          const yearB = b.model_year || 0;
          if (yearA !== yearB) return yearA - yearB;

          return (a.sale_price || 0) - (b.sale_price || 0);
        };
        localData.sort(sortFn);
        remoteData.sort(sortFn);

        setLocalInventory(localData);
        setRemoteInventory(remoteData);
      } catch (err) {
        console.error("Error fetching inventories:", err);
      }
      setIsLoading(false);
    };

    fetchInventories();
  }, [localStore, remoteStore]);

  // -------------------------------------------------------------------
  // 6) Filtering
  // -------------------------------------------------------------------
  const applyFilters = (vehicles) => {
    return vehicles.filter((v) => {
      if (filterSaleType && v.sale_type !== filterSaleType) return false;
      if (filterMake && v.make !== filterMake) return false;
      if (filterModel && v.model !== filterModel) return false;
      if (filterYear && String(v.model_year) !== filterYear) return false;
      return true;
    });
  };
  const filteredLocal = applyFilters(localInventory);
  const filteredRemote = applyFilters(remoteInventory);

  // -------------------------------------------------------------------
  // 7) Format currency
  // -------------------------------------------------------------------
  const formatCurrency = (num) => {
    if (num === null || num === undefined) return "";
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(num);
  };

  // -------------------------------------------------------------------
  // 8) Differences
  // -------------------------------------------------------------------
  const calcDiff = (localVal, remoteVal) => {
    return Number(remoteVal || 0) - Number(localVal || 0);
  };

  const calcDiffReviewMode = (localVal, remoteVal) => {
    return Number(localVal || 0) - Number(remoteVal || 0);
  };

  const calcDaysDiff = (localDate, remoteDate) => {
    if (!localDate || !remoteDate) return "";
    try {
      const dLocal = new Date(localDate);
      const dRemote = new Date(remoteDate);
      return Math.floor((dRemote - dLocal) / (1000 * 60 * 60 * 24));
    } catch {
      return "";
    }
  };

  const arrowIcon = (diff, isCurrency = false) => {
    const diffVal = isCurrency ? formatCurrency(Math.abs(diff)) : Math.abs(diff);
    if (diff > 0) {
      return <span style={{ color: "green" }}>▲ +{diffVal}</span>;
    } else if (diff < 0) {
      return <span style={{ color: "red" }}>▼ -{diffVal}</span>;
    }
    return <span>{diffVal}</span>;
  };

  // -------------------------------------------------------------------
  // 9) Image Carousels
  // -------------------------------------------------------------------
  const getLocalImgIndex = (i) => localImageIndexes[i] || 0;
  const getRemoteImgIndex = (i) => remoteImageIndexes[i] || 0;

  const handleLocalImgPrev = (rowIndex, total) => {
    setLocalImageIndexes((prev) => {
      const current = prev[rowIndex] || 0;
      const nextIndex = (current - 1 + total) % total;
      return { ...prev, [rowIndex]: nextIndex };
    });
  };
  const handleLocalImgNext = (rowIndex, total) => {
    setLocalImageIndexes((prev) => {
      const current = prev[rowIndex] || 0;
      const nextIndex = (current + 1) % total;
      return { ...prev, [rowIndex]: nextIndex };
    });
  };

  const handleRemoteImgPrev = (rowIndex, total) => {
    setRemoteImageIndexes((prev) => {
      const current = prev[rowIndex] || 0;
      const nextIndex = (current - 1 + total) % total;
      return { ...prev, [rowIndex]: nextIndex };
    });
  };
  const handleRemoteImgNext = (rowIndex, total) => {
    setRemoteImageIndexes((prev) => {
      const current = prev[rowIndex] || 0;
      const nextIndex = (current + 1) % total;
      return { ...prev, [rowIndex]: nextIndex };
    });
  };

  // -------------------------------------------------------------------
  // 10) "Select" for normal comparison + highlight + scroll
  // -------------------------------------------------------------------
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleSelectLocal = (vehicle) => {
    setSelectedLocalVehicle(vehicle);

    // if remote is already selected, scroll top
    if (selectedRemoteVehicle) {
      scrollToTop();
    }
  };
  const handleSelectRemote = (vehicle) => {
    setSelectedRemoteVehicle(vehicle);

    // if local is already selected, scroll top
    if (selectedLocalVehicle) {
      scrollToTop();
    }
  };
  const clearSelections = () => {
    setSelectedLocalVehicle(null);
    setSelectedRemoteVehicle(null);
  };

  // -------------------------------------------------------------------
  // 11) Request a new Trade (normal usage)
  // -------------------------------------------------------------------
  const handleRequestTrade = async () => {
    if (!selectedLocalVehicle || !selectedRemoteVehicle) {
      console.error("Need both local and remote vehicles selected!");
      return;
    }

    try {
      const token = localStorage.getItem("accessToken");
      if (!token) {
        console.error("No token found");
        return;
      }

      // Build the body to match EXACTLY what the server expects
      const body = {
        // The remote (requested) side
        requested_vin: selectedRemoteVehicle.vin_number,
        requested_stock_number: selectedRemoteVehicle.stock_number,
        requested_sale_type: selectedRemoteVehicle.sale_type,
        requested_store: remoteStore,

        // The local (requesting) side
        requesting_vin: selectedLocalVehicle.vin_number,
        requesting_stock_number: selectedLocalVehicle.stock_number,
        requesting_make: selectedLocalVehicle.make,
        requesting_model: selectedLocalVehicle.model,
        requesting_model_year: selectedLocalVehicle.model_year,
        requesting_sale_type: selectedLocalVehicle.sale_type,
        requesting_store: localStore,

        submitted_by: user?.first_name || "Unknown",
      };

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/request-trade`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(body),
        }
      );

      if (response.ok) {
        alert("Trade request sent!");
      } else {
        console.error("Failed to send trade request");
      }
    } catch (error) {
      console.error("Error sending trade request:", error);
    }
  };

  // -------------------------------------------------------------------
  // 12) Approve/Reject in "Review" mode
  // -------------------------------------------------------------------
  const handleApproveReview = async () => {
    if (!requestId) return;
    try {
      const token = localStorage.getItem("accessToken");
      const resp = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/approve-trade-request`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ request_id: requestId }),
        }
      );
      if (resp.ok) {
        alert("Trade approved!");
        // Optionally clear or navigate away
        setReviewLocalVehicle(null);
        setReviewRemoteVehicle(null);
      } else {
        console.error("Failed to approve trade");
      }
    } catch (err) {
      console.error("Error approving trade:", err);
    }
  };

  const handleRejectReview = async () => {
    if (!requestId) return;
    try {
      const token = localStorage.getItem("accessToken");
      const resp = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/reject-trade-request`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ request_id: requestId }),
        }
      );
      if (resp.ok) {
        alert("Trade rejected.");
        setReviewLocalVehicle(null);
        setReviewRemoteVehicle(null);
      } else {
        console.error("Failed to reject trade");
      }
    } catch (err) {
      console.error("Error rejecting trade:", err);
    }
  };

  // -------------------------------------------------------------------
  // 13) Render the "Review" side-by-side comparison if request_id
  // -------------------------------------------------------------------
  const renderReviewComparison = () => {
    if (!reviewLocalVehicle || !reviewRemoteVehicle) return null;

    // Compute differences as local - remote
    const priceDiff = calcDiffReviewMode(
      reviewLocalVehicle.sale_price,
      reviewRemoteVehicle.sale_price
    );
    const costDiff = calcDiffReviewMode(
      reviewLocalVehicle.inventory_cost,
      reviewRemoteVehicle.inventory_cost
    );
    const daysDiff = calcDaysDiff(
      reviewLocalVehicle.stock_in_date,
      reviewRemoteVehicle.stock_in_date
    );

    // images
    const localImg = reviewLocalVehicle.inventory_images?.[0];
    const remoteImg = reviewRemoteVehicle.inventory_images?.[0];

    return (
      <div className="focused-comparison" style={{ marginBottom: "2rem" }}>
        <h3>Review Trade Request</h3>
        <div className="focused-comparison-row">
          {/* Left Vehicle (Offered) */}
          <div className="focused-vehicle">
            <h4 className="vehicle-header">Offered Vehicle</h4>
            {localImg && (
              <div className="focused-image-container">
                <img src={localImg} alt="Offered local vehicle" />
              </div>
            )}
            <p>
              <strong>Make:</strong> {reviewLocalVehicle.make}
            </p>
            <p>
              <strong>Model:</strong> {reviewLocalVehicle.model}
            </p>
            <p>
              <strong>Year:</strong> {reviewLocalVehicle.model_year}
            </p>
            <p>
              <strong>Sale Price:</strong>{" "}
              {formatCurrency(reviewLocalVehicle.sale_price)}
            </p>
            <p>
              <strong>Inventory Cost:</strong>{" "}
              {formatCurrency(reviewLocalVehicle.inventory_cost)}
            </p>
            <p>
              <strong>VIN:</strong> {reviewLocalVehicle.vin_number}
            </p>
            <p>
              <strong>Stock In Date:</strong>{" "}
              {reviewLocalVehicle.stock_in_date}
            </p>
          </div>

          {/* Differences */}
          <div className="focused-differences">
            <p>
              <strong>Price Diff:</strong> {arrowIcon(priceDiff, true)}
            </p>
            <p>
              <strong>Cost Diff:</strong> {arrowIcon(costDiff, true)}
            </p>
            <p>
              <strong>Days Diff:</strong>{" "}
              {daysDiff > 0 ? `+${daysDiff}` : daysDiff}
            </p>
          </div>

          {/* Right Vehicle (Requested) */}
          <div className="focused-vehicle">
            <h4 className="vehicle-header">Requested Vehicle</h4>
            {remoteImg && (
              <div className="focused-image-container">
                <img src={remoteImg} alt="Requested remote vehicle" />
              </div>
            )}
            <p>
              <strong>Make:</strong> {reviewRemoteVehicle.make}
            </p>
            <p>
              <strong>Model:</strong> {reviewRemoteVehicle.model}
            </p>
            <p>
              <strong>Year:</strong> {reviewRemoteVehicle.model_year}
            </p>
            <p>
              <strong>Sale Price:</strong>{" "}
              {formatCurrency(reviewRemoteVehicle.sale_price)}
            </p>
            <p>
              <strong>Inventory Cost:</strong>{" "}
              {formatCurrency(reviewRemoteVehicle.inventory_cost)}
            </p>
            <p>
              <strong>VIN:</strong> {reviewRemoteVehicle.vin_number}
            </p>
            <p>
              <strong>Stock In Date:</strong>{" "}
              {reviewRemoteVehicle.stock_in_date}
            </p>
          </div>
        </div>

        {/* Approve/Reject Buttons */}
        <div style={{ marginTop: "1rem" }}>
          <button onClick={handleApproveReview}>Approve</button>
          <button style={{ marginLeft: "1rem" }} onClick={handleRejectReview}>
            Reject
          </button>
        </div>
      </div>
    );
  };

  // -------------------------------------------------------------------
  // 14) Normal "Focused Comparison" (local picks)
  // -------------------------------------------------------------------
  const renderSelectedComparison = () => {
    if (!selectedLocalVehicle || !selectedRemoteVehicle) return null;

    const priceDiff = calcDiff(
      selectedLocalVehicle.sale_price,
      selectedRemoteVehicle.sale_price
    );
    const costDiff = calcDiff(
      selectedLocalVehicle.inventory_cost,
      selectedRemoteVehicle.inventory_cost
    );
    const daysDiff = calcDaysDiff(
      selectedLocalVehicle.stock_in_date,
      selectedRemoteVehicle.stock_in_date
    );

    const localImg = selectedLocalVehicle.inventory_images?.[0];
    const remoteImg = selectedRemoteVehicle.inventory_images?.[0];

    return (
      <div className="focused-comparison" style={{ marginBottom: "2rem" }}>
        <h3>Selected Comparison</h3>
        <div className="focused-comparison-row">
          {/* Left selected (Offered) */}
          <div className="focused-vehicle">
            <h4 className="vehicle-header">Offered Vehicle</h4>
            {localImg && (
              <div className="focused-image-container">
                <img src={localImg} alt="Local vehicle" />
              </div>
            )}
            <p>
              <strong>Make:</strong> {selectedLocalVehicle.make}
            </p>
            <p>
              <strong>Model:</strong> {selectedLocalVehicle.model}
            </p>
            <p>
              <strong>Year:</strong> {selectedLocalVehicle.model_year}
            </p>
            <p>
              <strong>Sale Price:</strong>{" "}
              {formatCurrency(selectedLocalVehicle.sale_price)}
            </p>
            <p>
              <strong>Inventory Cost:</strong>{" "}
              {formatCurrency(selectedLocalVehicle.inventory_cost)}
            </p>
          </div>

          {/* Differences */}
          <div className="focused-differences">
            <p>
              <strong>Price Diff:</strong> {arrowIcon(priceDiff, true)}
            </p>
            <p>
              <strong>Cost Diff:</strong> {arrowIcon(costDiff, true)}
            </p>
            <p>
              <strong>Days Diff:</strong>{" "}
              {daysDiff > 0 ? `+${daysDiff}` : daysDiff}
            </p>
          </div>

          {/* Right selected (Requested) */}
          <div className="focused-vehicle">
            <h4 className="vehicle-header">Requested Vehicle</h4>
            {remoteImg && (
              <div className="focused-image-container">
                <img src={remoteImg} alt="Remote vehicle" />
              </div>
            )}
            <p>
              <strong>Make:</strong> {selectedRemoteVehicle.make}
            </p>
            <p>
              <strong>Model:</strong> {selectedRemoteVehicle.model}
            </p>
            <p>
              <strong>Year:</strong> {selectedRemoteVehicle.model_year}
            </p>
            <p>
              <strong>Sale Price:</strong>{" "}
              {formatCurrency(selectedRemoteVehicle.sale_price)}
            </p>
            <p>
              <strong>Inventory Cost:</strong>{" "}
              {formatCurrency(selectedRemoteVehicle.inventory_cost)}
            </p>
          </div>
        </div>

        <div style={{ marginTop: "1rem" }}>
          <button onClick={handleRequestTrade}>Request Trade</button>
          <button onClick={clearSelections} style={{ marginLeft: "0.5rem" }}>
            Clear Selections
          </button>
        </div>
      </div>
    );
  };

  // -------------------------------------------------------------------
  // 15) side-by-side table of local vs remote
  //     highlight if selected and add headers
  // -------------------------------------------------------------------
  const renderSideBySide = () => {
    const maxLen = Math.max(filteredLocal.length, filteredRemote.length);
    const rows = [];

    // Add headers
    rows.push(
      <div className="trade-row headers-row" key="headers">
        <div className="trade-col local-vehicle header-col">
          <strong>Internal Inventory</strong>
        </div>
        <div className="trade-col difference-col header-col">
          <strong>Differences</strong>
        </div>
        <div className="trade-col remote-vehicle header-col">
          <strong>Comparable Inventory</strong>
        </div>
      </div>
    );

    for (let i = 0; i < maxLen; i++) {
      const local = filteredLocal[i] || {};
      const remote = filteredRemote[i] || {};

      const priceDiff = calcDiff(local.sale_price, remote.sale_price);
      const costDiff = calcDiff(local.inventory_cost, remote.inventory_cost);
      const daysDiff = calcDaysDiff(local.stock_in_date, remote.stock_in_date);

      // Determine if local is selected => add highlight class
      const localSelected =
        selectedLocalVehicle && local.vin_number === selectedLocalVehicle.vin_number
          ? "selected-vehicle"
          : "";

      // Determine if remote is selected => add highlight class
      const remoteSelected =
        selectedRemoteVehicle && remote.vin_number === selectedRemoteVehicle.vin_number
          ? "selected-vehicle"
          : "";

      // Local image
      let localImageContent = null;
      if (local.inventory_images?.length > 0) {
        const idx = getLocalImgIndex(i);
        const url = local.inventory_images[idx];
        localImageContent = (
          <div className="images-container">
            <button
              onClick={() =>
                handleLocalImgPrev(i, local.inventory_images.length)
              }
            >
              ◀
            </button>
            <img src={url} alt={`Local vehicle ${idx}`} />
            <button
              onClick={() =>
                handleLocalImgNext(i, local.inventory_images.length)
              }
            >
              ▶
            </button>
          </div>
        );
      }

      // Remote image
      let remoteImageContent = null;
      if (remote.inventory_images?.length > 0) {
        const idx = getRemoteImgIndex(i);
        const url = remote.inventory_images[idx];
        remoteImageContent = (
          <div className="images-container">
            <button
              onClick={() =>
                handleRemoteImgPrev(i, remote.inventory_images.length)
              }
            >
              ◀
            </button>
            <img src={url} alt={`Remote vehicle ${idx}`} />
            <button
              onClick={() =>
                handleRemoteImgNext(i, remote.inventory_images.length)
              }
            >
              ▶
            </button>
          </div>
        );
      }

      rows.push(
        <div className="trade-row" key={i}>
          {/* Left column */}
          <div className={`trade-col local-vehicle ${localSelected}`}>
            {localImageContent}
            <p>
              <strong>Make:</strong> {local.make}
            </p>
            <p>
              <strong>Model:</strong> {local.model}
            </p>
            <p>
              <strong>Year:</strong> {local.model_year}
            </p>
            <p>
              <strong>Sale Type:</strong> {local.sale_type}
            </p>
            <p>
              <strong>Sale Price:</strong> {formatCurrency(local.sale_price)}
            </p>
            <p>
              <strong>Inventory Cost:</strong>{" "}
              {formatCurrency(local.inventory_cost)}
            </p>
            <p>
              <strong>Stock In Date:</strong> {local.stock_in_date}
            </p>

            {local.vin_number && (
              <button onClick={() => handleSelectLocal(local)}>Select</button>
            )}
          </div>

          {/* Middle column: differences */}
          <div className="trade-col difference-col">
            <p>
              <strong>Price Diff:</strong> {arrowIcon(priceDiff, true)}
            </p>
            <p>
              <strong>Cost Diff:</strong> {arrowIcon(costDiff, true)}
            </p>
            <p>
              <strong>Days Diff:</strong>{" "}
              {daysDiff ? (daysDiff > 0 ? `+${daysDiff}` : daysDiff) : daysDiff}
            </p>
          </div>

          {/* Right column */}
          <div className={`trade-col remote-vehicle ${remoteSelected}`}>
            {remoteImageContent}
            <p>
              <strong>Make:</strong> {remote.make}
            </p>
            <p>
              <strong>Model:</strong> {remote.model}
            </p>
            <p>
              <strong>Year:</strong> {remote.model_year}
            </p>
            <p>
              <strong>Sale Type:</strong> {remote.sale_type}
            </p>
            <p>
              <strong>Sale Price:</strong> {formatCurrency(remote.sale_price)}
            </p>
            <p>
              <strong>Inventory Cost:</strong>{" "}
              {formatCurrency(remote.inventory_cost)}
            </p>
            <p>
              <strong>Stock In Date:</strong> {remote.stock_in_date}
            </p>

            {remote.vin_number && (
              <button onClick={() => handleSelectRemote(remote)}>Select</button>
            )}
          </div>
        </div>
      );
    }
    return rows;
  };

  // -------------------------------------------------------------------
  // 16) Dynamic filter lists
  // -------------------------------------------------------------------
  const combinedAll = [...localInventory, ...remoteInventory];
  const filteredBySaleType = filterSaleType
    ? combinedAll.filter((v) => v.sale_type === filterSaleType)
    : combinedAll;

  const filteredByMake = filterMake
    ? filteredBySaleType.filter((v) => v.make === filterMake)
    : filteredBySaleType;

  const filteredByYear = filterYear
    ? filteredByMake.filter((v) => String(v.model_year) === filterYear)
    : filteredByMake;

  const makeList = Array.from(
    new Set(filteredBySaleType.map((v) => v.make).filter(Boolean))
  ).sort();

  const yearList = Array.from(
    new Set(filteredByMake.map((v) => v.model_year).filter(Boolean))
  ).sort((a, b) => a - b);

  const modelList = Array.from(
    new Set(filteredByYear.map((v) => v.model).filter(Boolean))
  ).sort();

  const saleTypes = Array.from(
    new Set(combinedAll.map((v) => v.sale_type).filter(Boolean))
  ).sort();

  // -------------------------------------------------------------------
  // 17) Render
  // -------------------------------------------------------------------
  const renderStoreDropdownOptions = () => {
    if (!user?.dataset_mapping) return null;
    return user.dataset_mapping.map((storeObj) => (
      <option key={storeObj.datasetId} value={storeObj.datasetId}>
        {storeObj.rooftop}
      </option>
    ));
  };

  return (
    <div className="inventory-trade-form">
      <h2>Inventory Trade Form</h2>

      {/* If we are in "review" mode => show the side-by-side review comparison */}
      {requestId && renderReviewComparison()}

      {/* Normal top-level comparison if user picks local vs. remote vehicles */}
      {renderSelectedComparison()}

      {/* Local & Remote Store selection row */}
      <div style={{ display: "flex", gap: "1rem", marginBottom: "1rem" }}>
        <div>
          <label>
            Local Store:
            <select
              style={{ marginLeft: 8 }}
              value={localStore}
              onChange={(e) => setLocalStore(e.target.value)}
            >
              <option value="">-- Select Local Store --</option>
              {renderStoreDropdownOptions()}
            </select>
          </label>
        </div>

        <div>
          <label>
            Compare/Trade with:
            <select
              style={{ marginLeft: 8 }}
              value={remoteStore}
              onChange={(e) => setRemoteStore(e.target.value)}
            >
              <option value="">-- Select a Remote Store --</option>
              {renderStoreDropdownOptions()}
            </select>
          </label>
        </div>
      </div>

      {/* Filter controls */}
      <div className="filters-container">
        <label>
          Sale Type:
          <select
            value={filterSaleType}
            onChange={(e) => setFilterSaleType(e.target.value)}
          >
            <option value="">All</option>
            {saleTypes.map((st) => (
              <option key={st} value={st}>
                {st}
              </option>
            ))}
          </select>
        </label>

        <label>
          Make:
          <select
            value={filterMake}
            onChange={(e) => setFilterMake(e.target.value)}
          >
            <option value="">All</option>
            {makeList.map((mk) => (
              <option key={mk} value={mk}>
                {mk}
              </option>
            ))}
          </select>
        </label>

        <label>
          Year:
          <select
            value={filterYear}
            onChange={(e) => setFilterYear(e.target.value)}
          >
            <option value="">All</option>
            {yearList.map((yr) => (
              <option key={yr} value={String(yr)}>
                {yr}
              </option>
            ))}
          </select>
        </label>

        <label>
          Model:
          <select
            value={filterModel}
            onChange={(e) => setFilterModel(e.target.value)}
          >
            <option value="">All</option>
            {modelList.map((md) => (
              <option key={md} value={md}>
                {md}
              </option>
            ))}
          </select>
        </label>

        <button
          onClick={() => {
            setFilterSaleType("");
            setFilterMake("");
            setFilterModel("");
            setFilterYear("");
          }}
        >
          Clear Filters
        </button>
      </div>

      {isLoading && <p>Loading inventories...</p>}

      {/* side-by-side view if both localStore & remoteStore are chosen */}
      {!isLoading && localStore && remoteStore && (
        <div className="trade-compare-container">{renderSideBySide()}</div>
      )}
    </div>
  );
}

export default InventoryTradeForm;
